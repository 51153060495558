<template>
  <TransitionRoot appear
                  :show="props.modelValue"
                  as="template"
  >
    <Dialog as="div"
            data-lenis-prevent
            @close="closeModal"
            class="relative z-50 antialiased"
    >
      <TransitionChild as="template"
                       enter="duration-300 ease-out"
                       enter-from="opacity-0"
                       enter-to="opacity-100"
                       leave="duration-200 ease-in"
                       leave-from="opacity-100"
                       leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-indigo/25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-full p-[4vw] md:p-[1.5vw] text-center">
          <TransitionChild as="template"
                           enter="duration-300 ease-out"
                           enter-from="opacity-0 scale-95"
                           enter-to="opacity-100 scale-100"
                           leave="duration-200 ease-in"
                           leave-from="opacity-100 scale-100"
                           leave-to="opacity-0 scale-95"
          >
            <DialogPanel class="w-full md:max-w-[42vw] text-left align-middle transition-all transform bg-white shadow-popup md:rounded-[1.25vw] px-[6vw] pt-[12vw] pb-[10vw] rounded-[5vw] md:px-[3vw] md:pt-[4vw] md:pb-[2.5vw] relative font-work">
              <button class="bg-indigo rounded-full flex items-center justify-center md:w-[2vw] md:h-[2vw] absolute md:top-[1.5vw] md:right-[1.5vw] hover:bg-opacity-75 duration-100 z-20 w-[8vw] h-[8vw] top-[4vw] right-[4vw]"
                      @click="closeModal"
              >
                <span class="sr-only">Close</span>
                <IconClose class="w-[6vw] h-[6vw] md:w-[1.5vw] md:h-[1.5vw] text-white" />
              </button>

              <div v-if="submit"
                   class="w-full md:py-[4vw]"
              >
                <div class="mx-auto md:max-w-[24vw] text-center text-indigo antialiased md:p-0 p-[6vw]">
                  <h3 class="md:mb-[1vw] md:text-[2.5vw] font-extrabold tracking-[-0.01em] md:leading-[2.5vw] text-indigo font-work antialiased text-[10vw] leading-[10vw] mb-[4vw]">
                    Your question has been submitted
                  </h3>
                  <div class="text-[5vw] font-work leading-[7vw] md:text-[1.25vw] md:leading-[1.75vw] text-indigo antialiased">
                    <p>Thank you for contacting us.</p>
                    <p>We’ll get back to you as soon as possible.</p>
                  </div>
                </div>
              </div>

              <div v-if="!submit">
                <DialogTitle as="h3"
                             class="md:text-[2.5vw] font-extrabold tracking-[-0.01em] md:leading-[2.5vw] text-indigo antialiased text-center md:text-left text-[10vw] leading-[10vw]"
                >
                  Submit your question
                </DialogTitle>
                <div class="mt-[4vw] md:mt-[1vw] text-center md:text-left md:px-0 px-[2vw]">
                  <p class="md:text-[1.25vw] md:leading-[1.75vw] text-indigo antialiased text-[5vw] leading-[7vw]">
                    Please provide your email address and question.
                  </p>
                </div>

                <Form class="md:mt-[2.25vw] mt-[8vw]"
                      @submit="handleSubmit"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                >
                  <div class="md:space-y-[1vw] space-y-[5vw]">
                    <div>
                      <SupportCardFormLabel for="name">
                        Your name*
                      </SupportCardFormLabel>

                      <Field name="name"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormInput name="name"
                                              id="name"
                                              type="text"
                                              :model-value="value"
                                              @update:model-value="handleChange"
                                              placeholder="Enter your name"
                                              :error="errors.name"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="email">
                        Your email address*
                      </SupportCardFormLabel>

                      <Field name="email"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormInput name="email"
                                              id="email"
                                              type="email"
                                              :model-value="value"
                                              @update:model-value="handleChange"
                                              placeholder="Enter your e-mail"
                                              :error="errors.email"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="age">
                        Your Age*
                      </SupportCardFormLabel>

                      <Field name="age"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormInput name="age"
                                              id="age"
                                              type="number"
                                              placeholder="Enter your age"
                                              inputmode="numeric"
                                              :model-value="value"
                                              @update:model-value="handleChange"
                                              :error="errors.age"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="country">
                        Country*
                      </SupportCardFormLabel>

                      <Field name="country"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormSelect name="country"
                                               id="country"
                                               :options="countryList"
                                               placeholder="Select your country"
                                               :required="true"
                                               :model-value="value"
                                               @update:model-value="handleChange"
                                               :error="errors.country"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="game">
                        Choose game*
                      </SupportCardFormLabel>

                      <Field name="game"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormSelect name="game"
                                               id="game"
                                               :options="gamesList"
                                               placeholder="Select your game"
                                               :required="true"
                                               :model-value="value"
                                               @update:model-value="handleChange"
                                               :error="errors.game"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="platform">
                        Select platform*
                      </SupportCardFormLabel>

                      <Field name="platform"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormSelect name="platform"
                                               id="platform"
                                               :options="platformList"
                                               placeholder="Select your platform"
                                               :required="true"
                                               :model-value="value"
                                               @update:model-value="handleChange"
                                               :error="errors.platform"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="issue">
                        Select the type of question/issue that you have
                      </SupportCardFormLabel>

                      <Field name="issue"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormSelect name="issue"
                                               id="issue"
                                               :options="issueList"
                                               :required="false"
                                               placeholder="Enter your subject"
                                               :model-value="value"
                                               @update:model-value="handleChange"
                                               :error="errors.issue"
                        />
                      </Field>
                    </div>

                    <div class="">
                      <SupportCardFormLabel for="question">
                        Write your question/describe your issue*
                      </SupportCardFormLabel>

                      <Field name="question"
                             v-slot="{ handleChange, value }"
                      >
                        <SupportCardFormInput name="question"
                                              type="textarea"
                                              rows="4"
                                              id="question"
                                              :model-value="value"
                                              @update:model-value="handleChange"
                                              :error="errors.question"
                                              placeholder="Write your question…"
                        />
                      </Field>
                    </div>

                    <div>
                      <SupportCardFormLabel for="attachments">
                        Attachments:
                      </SupportCardFormLabel>

                      <SupportCardFormInputFile v-model="tokens" />
                    </div>
                  </div>
                  <div class="md:mt-[2vw] mt-[5vw] flex justify-center md:justify-end">
                    <ButtonRegular class="flex-shrink-0">
                      Submit question
                    </ButtonRegular>
                  </div>
                </Form>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import * as yup from 'yup';
import { ref, watch } from 'vue';
import { useNuxtApp, useRuntimeConfig } from '#imports';
import { Form, Field } from 'vee-validate';
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';

type SupportCardPopupProps = {
  modelValue: boolean;
};

const schema = yup.object({
  question: yup.string().required(),
  game: yup.string().required(),
  platform: yup.string().required(),
  country: yup.string().required(),
  age: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().required().email()
});

const { $event } = useNuxtApp();
const emit = defineEmits(['update:modelValue']);
const props = defineProps<SupportCardPopupProps>();

const { baseUrl } = useRuntimeConfig().public;

const tokens = ref([]);
const submit = ref(false);

// watch props.modelValue
watch(
  () => props.modelValue,
  value => {
    if (value) {
      $event('support-form-open');
    } else {
      $event('support-form-close');
    }
  }
);

function handleSubmit(values: any) {

  $event('support-form-submit');
  // send zendesk api post request to create an anonymous request ticket
  const endpoint = 'https://outfit7.zendesk.com/api/v2/requests/';
  const data = {
    request: {
      custom_fields: [
        { id: 1900000825493, value: parseInt(values.age) },
        { id: 360026481997, value: values.country },
        { id: 360025400677, value: values.game },
        { id: 360025400977, value: values.platform },
        { id: 360025473337, value: values.issue }
      ],
      subject: 'Outfit7 Support',
      comment: {
        body: values.question + `\n\n------------------\nSubmitted from: ${baseUrl}/support`,
        ...(tokens.value.length > 0 && { uploads: tokens.value })
      },
      requester: {
        name: values.name,
        email: values.email
      },
      tags: ['web_widget']
    }
  };

  fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => response.json())
    .then(() => {
      submit.value = true;
    })
    .catch(error => {
      console.error('Error:', error);
    });

  submit.value = true;
}

function closeModal() {
  emit('update:modelValue', false);

  setTimeout(() => {
    submit.value = false;
  }, 300);
}

const gamesList = [
  {
    key: 'my_talking_hank',
    label: 'My Talking Hank Islands'
  },
  {
    key: 'my_talking_angela_2',
    label: 'My Talking Angela 2'
  },
  {
    key: 'my_talking_tom_friends',
    label: 'My Talking Tom Friends'
  },
  {
    key: 'hero_dash',
    label: 'Talking Tom Hero Dash'
  },
  {
    key: 'my_talking_tom_2',
    label: 'My Talking Tom 2'
  },
  {
    key: 'my_talking_angela',
    label: 'My Talking Angela'
  },
  {
    key: 'my_talking_tom',
    label: 'My Talking Tom'
  },
  {
    key: 'talking_tom_gold_run',
    label: 'Talking Tom Gold Run'
  },
  {
    key: 'talking_tom_time_rush',
    label: 'Talking Tom Time Rush'
  },
  {
    key: 'other_game',
    label: 'Other game'
  }
];

const platformList = [
  {
    key: 'android',
    label: 'Android'
  },
  {
    key: 'ios',
    label: 'iOS'
  }
];

const issueList = [
  {
    key: 'gameplay_and_new_updates',
    label: 'Gameplay and new updates'
  },
  {
    key: 'in-app_purchase_issues',
    label: 'In-app purchase issues'
  },
  {
    key: 'suggestions_and_feedback',
    label: 'Suggestions and feedback'
  },
  {
    key: 'loss_of_progress',
    label: 'Loss of progress'
  },
  {
    key: 'crashing_and_freezing',
    label: 'Crashing and freezing'
  },
  {
    key: 'other',
    label: 'Other'
  }
];

// From: https://gist.github.com/incredimike/1469814
const countryList = [
  { 'key': 'AF', 'label': 'Afghanistan' },
  { 'key': 'AL', 'label': 'Albania' },
  { 'key': 'DZ', 'label': 'Algeria' },
  { 'key': 'AS', 'label': 'American Samoa' },
  { 'key': 'AD', 'label': 'Andorra' },
  { 'key': 'AO', 'label': 'Angola' },
  { 'key': 'AI', 'label': 'Anguilla' },
  { 'key': 'AQ', 'label': 'Antarctica' },
  { 'key': 'AG', 'label': 'Antigua and Barbuda' },
  { 'key': 'AR', 'label': 'Argentina' },
  { 'key': 'AM', 'label': 'Armenia' },
  { 'key': 'AW', 'label': 'Aruba' },
  { 'key': 'AU', 'label': 'Australia' },
  { 'key': 'AT', 'label': 'Austria' },
  { 'key': 'AZ', 'label': 'Azerbaijan' },
  { 'key': 'BS', 'label': 'Bahamas (the)' },
  { 'key': 'BH', 'label': 'Bahrain' },
  { 'key': 'BD', 'label': 'Bangladesh' },
  { 'key': 'BB', 'label': 'Barbados' },
  { 'key': 'BY', 'label': 'Belarus' },
  { 'key': 'BE', 'label': 'Belgium' },
  { 'key': 'BZ', 'label': 'Belize' },
  { 'key': 'BJ', 'label': 'Benin' },
  { 'key': 'BM', 'label': 'Bermuda' },
  { 'key': 'BT', 'label': 'Bhutan' },
  { 'key': 'BO', 'label': 'Bolivia (Plurinational State of)' },
  { 'key': 'BQ', 'label': 'Bonaire, Sint Eustatius and Saba' },
  { 'key': 'BA', 'label': 'Bosnia and Herzegovina' },
  { 'key': 'BW', 'label': 'Botswana' },
  { 'key': 'BV', 'label': 'Bouvet Island' },
  { 'key': 'BR', 'label': 'Brazil' },
  { 'key': 'IO', 'label': 'British Indian Ocean Territory (the)' },
  { 'key': 'BN', 'label': 'Brunei Darussalam' },
  { 'key': 'BG', 'label': 'Bulgaria' },
  { 'key': 'BF', 'label': 'Burkina Faso' },
  { 'key': 'BI', 'label': 'Burundi' },
  { 'key': 'CV', 'label': 'Cabo Verde' },
  { 'key': 'KH', 'label': 'Cambodia' },
  { 'key': 'CM', 'label': 'Cameroon' },
  { 'key': 'CA', 'label': 'Canada' },
  { 'key': 'KY', 'label': 'Cayman Islands (the)' },
  { 'key': 'CF', 'label': 'Central African Republic (the)' },
  { 'key': 'TD', 'label': 'Chad' },
  { 'key': 'CL', 'label': 'Chile' },
  { 'key': 'CN', 'label': 'China' },
  { 'key': 'CX', 'label': 'Christmas Island' },
  { 'key': 'CC', 'label': 'Cocos (Keeling) Islands (the)' },
  { 'key': 'CO', 'label': 'Colombia' },
  { 'key': 'KM', 'label': 'Comoros (the)' },
  { 'key': 'CD', 'label': 'Congo (the Democratic Republic of the)' },
  { 'key': 'CG', 'label': 'Congo (the)' },
  { 'key': 'CK', 'label': 'Cook Islands (the)' },
  { 'key': 'CR', 'label': 'Costa Rica' },
  { 'key': 'HR', 'label': 'Croatia' },
  { 'key': 'CU', 'label': 'Cuba' },
  { 'key': 'CW', 'label': 'Curaçao' },
  { 'key': 'CY', 'label': 'Cyprus' },
  { 'key': 'CZ', 'label': 'Czechia' },
  { 'key': 'CI', 'label': 'Côte d\'Ivoire' },
  { 'key': 'DK', 'label': 'Denmark' },
  { 'key': 'DJ', 'label': 'Djibouti' },
  { 'key': 'DM', 'label': 'Dominica' },
  { 'key': 'DO', 'label': 'Dominican Republic (the)' },
  { 'key': 'EC', 'label': 'Ecuador' },
  { 'key': 'EG', 'label': 'Egypt' },
  { 'key': 'SV', 'label': 'El Salvador' },
  { 'key': 'GQ', 'label': 'Equatorial Guinea' },
  { 'key': 'ER', 'label': 'Eritrea' },
  { 'key': 'EE', 'label': 'Estonia' },
  { 'key': 'SZ', 'label': 'Eswatini' },
  { 'key': 'ET', 'label': 'Ethiopia' },
  { 'key': 'FK', 'label': 'Falkland Islands (the) [Malvinas]' },
  { 'key': 'FO', 'label': 'Faroe Islands (the)' },
  { 'key': 'FJ', 'label': 'Fiji' },
  { 'key': 'FI', 'label': 'Finland' },
  { 'key': 'FR', 'label': 'France' },
  { 'key': 'GF', 'label': 'French Guiana' },
  { 'key': 'PF', 'label': 'French Polynesia' },
  { 'key': 'TF', 'label': 'French Southern Territories (the)' },
  { 'key': 'GA', 'label': 'Gabon' },
  { 'key': 'GM', 'label': 'Gambia (the)' },
  { 'key': 'GE', 'label': 'Georgia' },
  { 'key': 'DE', 'label': 'Germany' },
  { 'key': 'GH', 'label': 'Ghana' },
  { 'key': 'GI', 'label': 'Gibraltar' },
  { 'key': 'GR', 'label': 'Greece' },
  { 'key': 'GL', 'label': 'Greenland' },
  { 'key': 'GD', 'label': 'Grenada' },
  { 'key': 'GP', 'label': 'Guadeloupe' },
  { 'key': 'GU', 'label': 'Guam' },
  { 'key': 'GT', 'label': 'Guatemala' },
  { 'key': 'GG', 'label': 'Guernsey' },
  { 'key': 'GN', 'label': 'Guinea' },
  { 'key': 'GW', 'label': 'Guinea-Bissau' },
  { 'key': 'GY', 'label': 'Guyana' },
  { 'key': 'HT', 'label': 'Haiti' },
  { 'key': 'HM', 'label': 'Heard Island and McDonald Islands' },
  { 'key': 'VA', 'label': 'Holy See (the)' },
  { 'key': 'HN', 'label': 'Honduras' },
  { 'key': 'HK', 'label': 'Hong Kong' },
  { 'key': 'HU', 'label': 'Hungary' },
  { 'key': 'IS', 'label': 'Iceland' },
  { 'key': 'IN', 'label': 'India' },
  { 'key': 'ID', 'label': 'Indonesia' },
  { 'key': 'IR', 'label': 'Iran (Islamic Republic of)' },
  { 'key': 'IQ', 'label': 'Iraq' },
  { 'key': 'IE', 'label': 'Ireland' },
  { 'key': 'IM', 'label': 'Isle of Man' },
  { 'key': 'IL', 'label': 'Israel' },
  { 'key': 'IT', 'label': 'Italy' },
  { 'key': 'JM', 'label': 'Jamaica' },
  { 'key': 'JP', 'label': 'Japan' },
  { 'key': 'JE', 'label': 'Jersey' },
  { 'key': 'JO', 'label': 'Jordan' },
  { 'key': 'KZ', 'label': 'Kazakhstan' },
  { 'key': 'KE', 'label': 'Kenya' },
  { 'key': 'KI', 'label': 'Kiribati' },
  { 'key': 'KP', 'label': 'Korea (the Democratic People\'s Republic of)' },
  { 'key': 'KR', 'label': 'Korea (the Republic of)' },
  { 'key': 'KW', 'label': 'Kuwait' },
  { 'key': 'KG', 'label': 'Kyrgyzstan' },
  { 'key': 'LA', 'label': 'Lao People\'s Democratic Republic (the)' },
  { 'key': 'LV', 'label': 'Latvia' },
  { 'key': 'LB', 'label': 'Lebanon' },
  { 'key': 'LS', 'label': 'Lesotho' },
  { 'key': 'LR', 'label': 'Liberia' },
  { 'key': 'LY', 'label': 'Libya' },
  { 'key': 'LI', 'label': 'Liechtenstein' },
  { 'key': 'LT', 'label': 'Lithuania' },
  { 'key': 'LU', 'label': 'Luxembourg' },
  { 'key': 'MO', 'label': 'Macao' },
  { 'key': 'MG', 'label': 'Madagascar' },
  { 'key': 'MW', 'label': 'Malawi' },
  { 'key': 'MY', 'label': 'Malaysia' },
  { 'key': 'MV', 'label': 'Maldives' },
  { 'key': 'ML', 'label': 'Mali' },
  { 'key': 'MT', 'label': 'Malta' },
  { 'key': 'MH', 'label': 'Marshall Islands (the)' },
  { 'key': 'MQ', 'label': 'Martinique' },
  { 'key': 'MR', 'label': 'Mauritania' },
  { 'key': 'MU', 'label': 'Mauritius' },
  { 'key': 'YT', 'label': 'Mayotte' },
  { 'key': 'MX', 'label': 'Mexico' },
  { 'key': 'FM', 'label': 'Micronesia (Federated States of)' },
  { 'key': 'MD', 'label': 'Moldova (the Republic of)' },
  { 'key': 'MC', 'label': 'Monaco' },
  { 'key': 'MN', 'label': 'Mongolia' },
  { 'key': 'ME', 'label': 'Montenegro' },
  { 'key': 'MS', 'label': 'Montserrat' },
  { 'key': 'MA', 'label': 'Morocco' },
  { 'key': 'MZ', 'label': 'Mozambique' },
  { 'key': 'MM', 'label': 'Myanmar' },
  { 'key': 'NA', 'label': 'Namibia' },
  { 'key': 'NR', 'label': 'Nauru' },
  { 'key': 'NP', 'label': 'Nepal' },
  { 'key': 'NL', 'label': 'Netherlands (the)' },
  { 'key': 'NC', 'label': 'New Caledonia' },
  { 'key': 'NZ', 'label': 'New Zealand' },
  { 'key': 'NI', 'label': 'Nicaragua' },
  { 'key': 'NE', 'label': 'Niger (the)' },
  { 'key': 'NG', 'label': 'Nigeria' },
  { 'key': 'NU', 'label': 'Niue' },
  { 'key': 'NF', 'label': 'Norfolk Island' },
  { 'key': 'MP', 'label': 'Northern Mariana Islands (the)' },
  { 'key': 'NO', 'label': 'Norway' },
  { 'key': 'OM', 'label': 'Oman' },
  { 'key': 'PK', 'label': 'Pakistan' },
  { 'key': 'PW', 'label': 'Palau' },
  { 'key': 'PS', 'label': 'Palestine, State of' },
  { 'key': 'PA', 'label': 'Panama' },
  { 'key': 'PG', 'label': 'Papua New Guinea' },
  { 'key': 'PY', 'label': 'Paraguay' },
  { 'key': 'PE', 'label': 'Peru' },
  { 'key': 'PH', 'label': 'Philippines (the)' },
  { 'key': 'PN', 'label': 'Pitcairn' },
  { 'key': 'PL', 'label': 'Poland' },
  { 'key': 'PT', 'label': 'Portugal' },
  { 'key': 'PR', 'label': 'Puerto Rico' },
  { 'key': 'QA', 'label': 'Qatar' },
  { 'key': 'MK', 'label': 'Republic of North Macedonia' },
  { 'key': 'RO', 'label': 'Romania' },
  { 'key': 'RU', 'label': 'Russian Federation (the)' },
  { 'key': 'RW', 'label': 'Rwanda' },
  { 'key': 'RE', 'label': 'Réunion' },
  { 'key': 'BL', 'label': 'Saint Barthélemy' },
  { 'key': 'SH', 'label': 'Saint Helena, Ascension and Tristan da Cunha' },
  { 'key': 'KN', 'label': 'Saint Kitts and Nevis' },
  { 'key': 'LC', 'label': 'Saint Lucia' },
  { 'key': 'MF', 'label': 'Saint Martin (French part)' },
  { 'key': 'PM', 'label': 'Saint Pierre and Miquelon' },
  { 'key': 'VC', 'label': 'Saint Vincent and the Grenadines' },
  { 'key': 'WS', 'label': 'Samoa' },
  { 'key': 'SM', 'label': 'San Marino' },
  { 'key': 'ST', 'label': 'Sao Tome and Principe' },
  { 'key': 'SA', 'label': 'Saudi Arabia' },
  { 'key': 'SN', 'label': 'Senegal' },
  { 'key': 'RS', 'label': 'Serbia' },
  { 'key': 'SC', 'label': 'Seychelles' },
  { 'key': 'SL', 'label': 'Sierra Leone' },
  { 'key': 'SG', 'label': 'Singapore' },
  { 'key': 'SX', 'label': 'Sint Maarten (Dutch part)' },
  { 'key': 'SK', 'label': 'Slovakia' },
  { 'key': 'SI', 'label': 'Slovenia' },
  { 'key': 'SB', 'label': 'Solomon Islands' },
  { 'key': 'SO', 'label': 'Somalia' },
  { 'key': 'ZA', 'label': 'South Africa' },
  { 'key': 'GS', 'label': 'South Georgia and the South Sandwich Islands' },
  { 'key': 'SS', 'label': 'South Sudan' },
  { 'key': 'ES', 'label': 'Spain' },
  { 'key': 'LK', 'label': 'Sri Lanka' },
  { 'key': 'SD', 'label': 'Sudan (the)' },
  { 'key': 'SR', 'label': 'Surilabel' },
  { 'key': 'SJ', 'label': 'Svalbard and Jan Mayen' },
  { 'key': 'SE', 'label': 'Sweden' },
  { 'key': 'CH', 'label': 'Switzerland' },
  { 'key': 'SY', 'label': 'Syrian Arab Republic' },
  { 'key': 'TW', 'label': 'Taiwan' },
  { 'key': 'TJ', 'label': 'Tajikistan' },
  { 'key': 'TZ', 'label': 'Tanzania, United Republic of' },
  { 'key': 'TH', 'label': 'Thailand' },
  { 'key': 'TL', 'label': 'Timor-Leste' },
  { 'key': 'TG', 'label': 'Togo' },
  { 'key': 'TK', 'label': 'Tokelau' },
  { 'key': 'TO', 'label': 'Tonga' },
  { 'key': 'TT', 'label': 'Trinidad and Tobago' },
  { 'key': 'TN', 'label': 'Tunisia' },
  { 'key': 'TR', 'label': 'Turkey' },
  { 'key': 'TM', 'label': 'Turkmenistan' },
  { 'key': 'TC', 'label': 'Turks and Caicos Islands (the)' },
  { 'key': 'TV', 'label': 'Tuvalu' },
  { 'key': 'UG', 'label': 'Uganda' },
  { 'key': 'UA', 'label': 'Ukraine' },
  { 'key': 'AE', 'label': 'United Arab Emirates (the)' },
  { 'key': 'GB', 'label': 'United Kingdom of Great Britain and Northern Ireland (the)' },
  { 'key': 'UM', 'label': 'United States Minor Outlying Islands (the)' },
  { 'key': 'US', 'label': 'United States of America (the)' },
  { 'key': 'UY', 'label': 'Uruguay' },
  { 'key': 'UZ', 'label': 'Uzbekistan' },
  { 'key': 'VU', 'label': 'Vanuatu' },
  { 'key': 'VE', 'label': 'Venezuela (Bolivarian Republic of)' },
  { 'key': 'VN', 'label': 'Viet Nam' },
  { 'key': 'VG', 'label': 'Virgin Islands (British)' },
  { 'key': 'VI', 'label': 'Virgin Islands (U.S.)' },
  { 'key': 'WF', 'label': 'Wallis and Futuna' },
  { 'key': 'EH', 'label': 'Western Sahara' },
  { 'key': 'YE', 'label': 'Yemen' },
  { 'key': 'ZM', 'label': 'Zambia' },
  { 'key': 'ZW', 'label': 'Zimbabwe' },
  { 'key': 'AX', 'label': 'Åland Islands' }
];
</script>
