<template>
  <div class="relative">
    <component :is="inputType"
               :type="type"
               :value="modelValue"
               min="1"
               :placeholder="placeholder"
               @input="change"
               class="rounded-[2.5vw] text-[4.25vw] leading-[6vw] md:rounded-[0.625vw] text-indigo antialiased md:text-[1.125vw] md:leading-[1.5vw] placeholder:text-opacity-50 md:p-[1vw] p-[4vw] w-full focus:ring-1 focus:ring-indigo focus:outline-none border"
               :class="[{
                 'border-red': error.length > 0,
                 'border-transparent': error.length === 0,
               }, backgroundColor]"
    />
    <div v-if="error.length > 0"
         class="absolute text-[3.25vw] md:text-[0.8125vw] md:bottom-[-1.25vw] bottom-[-5vw] leading-[5vw] md:leading-[1.25] text-red text-right w-full capitalize"
    >
      {{ error }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type SupportCardFormInputProps = {
    type: 'text' | 'email' | 'textarea' | 'number'
    placeholder: string;
    modelValue?: string;
    error?: string;
    keyboard?: 'default' | 'numeric';
    backgroundColor: string;
}

const emit = defineEmits(['update:modelValue']);
const props = withDefaults(defineProps<SupportCardFormInputProps>(), {
  keyboard: 'default',
  modelValue: '',
  error: '',
  backgroundColor: 'bg-indigo/10'
});

const inputType = computed(() => props.type === 'textarea' ? 'textarea' : 'input');

function change(event: Event) {
  emit('update:modelValue', event.target.value);
}
</script>

<style>
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
</style>
